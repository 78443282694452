import Axios from 'axios'
import {apiEnv} from '../index'

const apiStaging = 'https://proxy.solease.nl/test'

const apiLive = 'https://proxy.solease.nl'

const getApi = () => apiEnv === 'LIVE' ? apiLive : apiStaging

const getRequest = (url: string, params: any, onSuccess: any, onError: any) => {
  Axios.get(getApi() + url, { params: params }).then((res) => {
    onSuccess(res.data, res.status)
  }).catch(error => onError())
}

const postRequest = (url: string, body: any, onSuccess: any, onError: any) => {
  Axios.post(getApi() + url, body). then((res) => {
    onSuccess(res.data)
  }).catch(error => onError())
}

export { getRequest, postRequest}
