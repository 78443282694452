import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import { DakCheckTool } from './UI/pages/DakCheckTool'
import 'assets/styles/global.css'
import OptionsContextProvider from 'utils/OptionsContext'

const App = (props: {contractDisabled: boolean, leadsource: number}) => {

  return (
    <div className='app'>
      <Router>
        <Switch>
          <Route path='/'>
            <OptionsContextProvider contractDisabled={props.contractDisabled} leadsource={props.leadsource}>
              <DakCheckTool />
            </OptionsContextProvider>
          </Route>
        </Switch>
      </Router>
    </div>
  )
}

export { App }

