import styled from 'styled-components'
import { GoogleMap } from '../../Map'
import { Carousel } from 'react-responsive-carousel'
import 'react-responsive-carousel/lib/styles/carousel.min.css'
import { useState } from 'react'

const Wrapper = styled.div``

const Title = styled.p`
  font-family: 'EnzoOT', sans-serif;
  font-size: 26px;
  font-weight: 300;

  @media (max-width: 600px) {
    font-size: 22px;
  }
`

const ImagesWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
  @media (max-width: 599px) {
    display: none;
  }
`

const Text = styled.p`
  font-size: 20px;
  margin: 0;

  @media (max-width: 600px) {
    font-size: 15px;
  }
`

const MainParagraph = styled.p`
  font-family: 'EnzoOT', sans-serif;
  font-size: 22px;
  font-weight: 300;
  
  @media (max-width: 600px){
    font-size: 20px;
  }
`

const ProsBox = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  height: 240px;
  width: 280px;
  padding: 10px;
  text-align: left;
  border: 1px solid rgba(102, 102, 102, 0.2);
  font-size: 16px;

  p:nth-child(1) {
    font-size: 22px;
    font-family: 'EnzoOT', sans-serif;
    font-weight: 300;
    margin: 0;
    margin-bottom: 10px;
  }

  div:not(:last-child) {
    display: flex;
    align-items: center;
    margin-bottom: 11px;

    img {
      margin-right: 10px;
      height: 20px;
      filter: invert(68%) sepia(10%) saturate(1367%) hue-rotate(154deg) brightness(103%) contrast(93%);
    }
  }

  div:nth-child(2) {
    display: none;
  }

  @media (max-width: 600px) {
    width: 100%;
    height: 155px;
    padding: 5px;
    margin-bottom: 15px;

    div:nth-child(3), div:nth-child(4) {
      display: none;
    }

    p:nth-child(1) {
      margin-bottom: 5px;
      font-size: 20px;
    }

    div:nth-child(2) {
      display: flex;
    }

    div:not(:last-child) {
      margin-bottom: 4px;
      height: 21px;
      letter-spacing: -.5px;
      font-weight: 300;

      img {
        margin-right: 3px;
      }
    }
  }
  
  @media (max-width: 365px){
    height: 200px;
    div:not(:last-child) {
      margin-bottom: 4px;
      height: 35px;
    }
  }
`

const BoxesWrapper = styled('div')<{ center?: boolean }>`
  display: flex;
  justify-content: ${props => props.center ? 'center' : 'space-between'};
  margin-top: 15px;

  @media (max-width: 600px) {
    flex-direction: column-reverse;
  }
`

const StyledCarousel = styled(Carousel)`
  position: relative;
  margin: auto;
  width: 300px;
  margin-bottom: 20px;

  .carousel {
    overflow: visible !important;
    
    .dot{
      width: 15px;
      height: 15px;
      
      opacity: .7;
    }

    .slide:nth-child(3) {
      div {
        pointer-events: none;
      }
    }
  }

  @media (min-width: 600px) {
    display: none;
  }
`

const Button = styled.div`
  width: 100%;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 300;
  cursor: pointer;
  font-size: 16px;
  background: #5fbade;
  color: white;
  align-self: center;
  margin-top: auto;

  @media (max-width: 600px) {
    width: 70%;
  }
`

const AddressNotFoundBlock = styled.div`
  font-size: 14px;
  p{
    margin: 0;
  }
  img{
    margin-bottom: 21px;
    height: 194px;
  }
  
  div:last-child{
    width: 50%; 
    margin: auto; 
    margin-top: 20px;
  }

  @media (max-width: 600px) {
    div:last-child{
      width: 90%;
    }
  }
`

const MobileImageWrapper = styled.div`
  margin: auto;
  width: 300px;
  margin-bottom: 20px;

  @media (min-width: 600px) {
    display: none;
  }
`

const BackButton = styled('div')<{top40?: boolean}>`
  margin-top: ${props => props.top40 ? '40px' : '10px'};
  font-size: 16px;
  cursor: pointer;
  color: rgba(46, 42, 40, 0.6);

  @media (max-width: 600px) {
    margin: 5px 0 !important;
  }
`

const AddressConfirmation = (props: { coordinates: { lat: number, lng: number }, address: string, setStep(step: number): void, addressNotFound: boolean, setProxyAddress(): void }) => {
  const { coordinates, address, setStep, addressNotFound, setProxyAddress } = props

  const [streetViewHidden, setStreetViewHidden] = useState(false)

  const checkedDataIcon = require('assets/icons/checkedData.svg').default
  const xIcon = require('assets/icons/xIcon.svg').default
  const woodsImage = require('assets/icons/woods.png').default

  return (
    <Wrapper>
      {
        !addressNotFound ?
          <div>
            <Title>WE HEBBEN JE DAK OP HET OOG!</Title>
            <Text style={{ fontSize: '16px' }}>Gevonden adres: <b>{address}</b></Text>
            {
              !streetViewHidden ?
                <StyledCarousel
                  autoPlay={false} showStatus={false}
                  showThumbs={false} infiniteLoop={true}
                  showArrows={false} >
                  <GoogleMap lat={coordinates.lat} lng={coordinates.lng} />
                  <GoogleMap lat={coordinates.lat} lng={coordinates.lng}
                             streetView={true}
                             hideStreetView={()=>setStreetViewHidden(true)} />
                </StyledCarousel> :
                <MobileImageWrapper>
                  <GoogleMap lat={coordinates.lat} lng={coordinates.lng} />
                </MobileImageWrapper>
            }
            <ImagesWrapper>
              {
                !streetViewHidden &&
                  <GoogleMap lat={coordinates.lat} lng={coordinates.lng}
                             streetView={true}
                             hideStreetView={()=>setStreetViewHidden(true)} />
              }
              <GoogleMap lat={coordinates.lat} lng={coordinates.lng} />
            </ImagesWrapper>
            <MainParagraph>Kies de nauwkeurigheid van je dakcheck</MainParagraph>
          </div> :
          <AddressNotFoundBlock>
            <Title>WE ZIEN DOOR DE BOMEN JOUW DAK NIET MEER</Title>
            <img alt='' src={woodsImage} />
            <p>Onze software kan geen dak vinden op </p>
            <p>Postcode: <b>{address.split(',')[0]}</b></p>
            <p>Huisnummer: <b>{address.split(',')[1]}</b></p> <br/>
            <p>Vul je gegevens aan en dan zoeken onze <br/> experts je huis op een andere manier. </p>
            <Button onClick={()=>setStep(10)}>GEGEVENS AANVULLEN</Button>
          </AddressNotFoundBlock>
      }
      {
        !addressNotFound &&
        <BoxesWrapper center={addressNotFound}>

          <ProsBox>
            <p>Online dakcheck</p>
            <div><img src={checkedDataIcon} alt='' /> Indicatie van aantal panelen door software</div>
            <div><img src={checkedDataIcon} alt='' /> Indicatie van aantal panelen</div>
            <div><img src={checkedDataIcon} alt='' /> Gegenereerd door software</div>
            <div><img src={checkedDataIcon} alt='' /> Kost slechts 1 minuut</div>
            <div><img style={{ filter: 'none' }} src={xIcon} alt='' /> Bevat geen aanbod of prijs</div>
            <Button
              onClick={() => setProxyAddress()}>DOE DE ONLINE DAKCHECK</Button>
          </ProsBox>

          <ProsBox>
            <p>Advies van experts</p>
            <div><img src={checkedDataIcon} alt='' /> Professioneel legplan & persoonlijk advies</div>
            <div><img src={checkedDataIcon} alt='' /> Professioneel legplan</div>
            <div><img src={checkedDataIcon} alt='' /> Persoonlijk advies</div>
            <div><img src={checkedDataIcon} alt='' /> Inzicht in kosten en opbrengst</div>
            <div><img src={checkedDataIcon} alt='' /> Meest gekozen</div>
            <Button onClick={() => setStep(10)}>VRAAG ADVIES VAN EXPERTS</Button>
          </ProsBox>
        </BoxesWrapper>
      }
      <BackButton top40={!addressNotFound} onClick={() => setStep(1)}>Terug</BackButton>
    </Wrapper>
  )
}

export { AddressConfirmation }
