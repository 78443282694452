import styled from 'styled-components'
import { Controls } from '../Controls'
import { useState } from 'react'
import { Input } from '../Input'
import { Error } from '../Shared'
import ReactTooltip from 'react-tooltip'

const Wrapper = styled.div`
  max-width: 480px;
  margin: auto;
`

const Title = styled.p`
  font-family: 'EnzoOT', sans-serif;
  font-size: 26px;
  font-weight: 300;
`

const ImageWrapper = styled.div`
  margin: auto;
  width: 480px;
  height: 295px;
  position: relative;

  img {
    width: 100%;
    height: 100%;
  }

  img:nth-child(2) {
    z-index: 100;
    position: absolute;
    left: 0;
  }

  @media (max-width: 600px) {
    width: 100%;
    height: 229px;
  }
`

const PersonalInfoWrapper = styled.div`
  display: flex;
  justify-content: space-between;

  @media (max-width: 600px) {
    flex-direction: column;
    align-items: center;
    label {
      width: 80%;
    }
  }
`

const Tooltip = styled(ReactTooltip)`
  font-size: 10px;
  font-weight: 300;
`

const Results = (props: { setStep(step: number): void, results: any, submitRoofCheck(firstName: string, lastName: string, email: string): void }) => {

  const [firstName, setFirstName] = useState('')
  const [firstNameError, setFirstNameError] = useState(false)
  const [lastName, setLastName] = useState('')
  const [lastNameError, setLastNameError] = useState(false)
  const [email, setEmail] = useState('')
  const [emailError, setEmailError] = useState(false)
  const [fieldsEmptyError, setFieldsEmptyError] = useState(false)
  const [fieldsInvalidError, setFieldsInvalidError] = useState(false)

  const { results } = props
  const emailRegex = /^[\w-.]+[0-9a-z]+@([\w-]+\.)+[\w-]{2,4}$/

  const setFirstNameField = (value: string) => {
    setFirstName(value)
    !value ? setFirstNameError(true) : setFirstNameError(false)
  }

  const setLastNameField = (value: string) => {
    value.length < 32 && setLastName(value)
    !value ? setLastNameError(true) : setLastNameError(false)
  }

  const setEmailField = (value: string) => {
    setEmail(value)
    emailRegex.test(value) ? setEmailError(false) : setEmailError(true)
  }

  const validateFields = () => {
    let hasErrors = false
    if (!firstName) {
      setFirstNameError(true)
      hasErrors = true
    }
    if (!lastName) {
      setLastNameError(true)
      hasErrors = true
    }
    if (!email) {
      setEmailError(true)
      hasErrors = true
    }
    !email || !lastName || !firstName ? setFieldsEmptyError(true) : setFieldsEmptyError(false)
    emailRegex.test(email) ? setFieldsInvalidError(false) : setFieldsInvalidError(true)

    return hasErrors
  }

  const submitFields = () => {
    validateFields()
    if (!validateFields() && !firstNameError && !lastNameError && !emailError) {
      props.submitRoofCheck(firstName, lastName, email)
    }
  }

  return (
    <Wrapper>
      <Title>JOUW DAK IS GESCHIKT!</Title>
      <p>{`Bij jouw dak en verbruik passen ${results.modules} zonnepanelen.`}</p>
      <ImageWrapper>
        <img alt='' crossOrigin={'anonymous'} src={results.background_map_url} />
        <img alt='' crossOrigin={'anonymous'} src={results.imgsrc} />
      </ImageWrapper>
      <p>Bijna klaar! Vul alleen nog je e-mailadres in en ontvang het volledige resultaat met daarin de opbrengst,
        besparing en terugverdientijd.</p>
      <PersonalInfoWrapper>
        <Input
          value={email}
          error={emailError}
          correct={/^[\w-.]+[0-9a-z]+@([\w-]+\.)+[\w-]{2,4}$/.test(email)}
          fluid={true}
          onChange={setEmailField}
          tooltip='Vul hier het e-mailadres in waarop je jouw advies wilt ontvangen.'
          placeholder='E-mail' />
        <Tooltip
          multiline={true}
          place='top'
          type='dark'
          effect='solid' />
      </PersonalInfoWrapper>
      <PersonalInfoWrapper>
        <Input
          value={firstName}
          error={firstNameError}
          correct={firstName !== ''}
          onChange={setFirstNameField}
          placeholder='Voornaam' />
        <Input
          value={lastName}
          error={lastNameError}
          correct={lastName !== ''}
          onChange={setLastNameField}
          placeholder='Achternaam' />
      </PersonalInfoWrapper>

      <Controls step={4} next={() => submitFields()} back={() => props.setStep(3)} />
      {
        fieldsEmptyError ?
          <Error>Deze gegevens hebben we nodig om je aanvraag voor zonnepanelen goed te verwerken.</Error> :
          fieldsInvalidError &&
          <Error>Oeps, het gaat niet goed. Controleer de rood gemarkeerde velden.</Error>
      }
    </Wrapper>
  )
}

export { Results }
