import styled from 'styled-components'
import { Controls } from '../Controls'

const Wrapper = styled.div`
  max-width: 480px;
  margin: auto;
`

const Title = styled.p`
  font-family: 'EnzoOT', sans-serif;
  font-size: 26px;
  font-weight: 300;
`

const ResultsError = (props: { setStep(step: number): void}) => {

  return (
    <Wrapper>
      <Title>JOUW DAK IS MOGELIJK GESCHIKT</Title>
      <p>Je hebt een uniek dak! Onze software kan geen automatisch legplan genereren. </p>
      <p>Vul je gegevens aan via onderstaande knop en onze experts bekijken of jouw dak geschikt is voor zonnepanelen.</p>
      <Controls step={9} next={() => props.setStep(10)} back={() => props.setStep(3)} />
    </Wrapper>
  )
}

export { ResultsError }
