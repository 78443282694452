import styled, { css } from 'styled-components'
import { Controls } from '../Controls'
import { useState } from 'react'
import { getRequest } from '../../../../utils/Api'
import ReactTooltip from 'react-tooltip'
import checkedIcon from 'assets/icons/checkedIcon.svg'
import { Error } from '../Shared'

const Wrapper = styled.div``

const Title = styled.p`
  font-family: 'EnzoOT', sans-serif;
  font-size: 26px;
  font-weight: 300;
  
  @media (max-width: 600px){
    font-size: 22px;
  }
`

const RadioWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-bottom: 20px;
`

const Tooltip = styled(ReactTooltip)`
  font-size: 10px;
  font-weight: 300;
`

const RadioBox = styled('div')<{ large?: boolean, active?: boolean }>`
  position: relative;
  box-sizing: border-box;
  width: ${props => props.large ? '169px' : '141px'};
  height: 137px;
  background: #ffffff;
  border: 1px solid ${props => props.active ? '#5FBADE' : '#ced4da'};
  box-shadow: ${props => props.active && '0 0 3px 2px #5fbade'};
  padding: 10px 0;
  margin: 5px;
  cursor: pointer;

  &:after {
    top: 10px;
    left: 10px;
    content: '';
    width: 18px;
    height: 18px;
    border: 1px solid #000000;
    border-radius: 50%;
    position: absolute;
    background-image: url("${checkedIcon}");
    filter: invert(68%) sepia(10%) saturate(1367%) hue-rotate(154deg) brightness(103%) contrast(93%);
    ${props => !props.active && css`
      background-image: unset;
      filter: none
    `};
  }

  p {
    margin: 0;
  }
  
  @media (max-width: 600px){
    width: 100%;
    height: 58px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    max-width: 350px;
    div{
      width: 100px;
      order: 2;
      height: 55px;
      img{
        height: 55px;
      }
    }
    p{
      order: 3;
      width: 144px;
      font-size: 16px;
      margin-left: auto;
      margin-right: 9px;
    }
    &:after{
      order: 1;
      position: unset;
      margin: 0 10px;
    }
  }
`

const ConsumptionControl = styled('div')<{ error: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 22px;
  font-weight: bold;
  margin-top: 10px;

  ${props => props.error && 'box-shadow: 0 0 3px 2px #FF0000;'}
  img {
    cursor: pointer;
    width: 28px;
    background-color: white;
    border-radius: 50%;
  }

  p {
    margin: 0;
    width: 200px;
  }

  @media(max-width: 600px){
    img{
      width: 33px;
    }
    p{
      font-size: 20px;
    }
  }
`

const Text = styled.p`
  font-size: 22px;
  margin: 0;
  font-family: 'EnzoOT',sans-serif;
  font-weight: 300;
  
  @media (max-width: 600px){
    font-size: 20px;
    font-weight: 300;
  }
`

interface ConsumptionProps {
  setStep(step: number): void
  transaction: number,
  getResults(repetition: number): void
  consumption: number
  setConsumption(value: number): void
  activeGroup: number
  setActiveGroup(value: number): void
  leadsource: string
}

const Consumption = (props: ConsumptionProps) => {

  const { consumption, setConsumption, activeGroup, setActiveGroup} = props
  const [consumtionError, setConsumptionError] = useState(false)

  const person1Img = require('assets/icons/1_person.png').default
  const person2Img = require('assets/icons/2_persons.png').default
  const person3Img = require('assets/icons/3_persons.png').default
  const person4Img = require('assets/icons/4_persons.png').default
  const person5Img = require('assets/icons/5_persons.png').default
  const minusIcon = require('assets/icons/minus.svg').default
  const plusIcon = require('assets/icons/plus.svg').default
  const infoIcon = require('assets/icons/info.svg').default

  const setConsumptionField = (consumption: number) => {
    if (consumption >= 1600 && consumption <= 10000)
      setConsumption(consumption)
    setConsumptionError(false)
  }

  const setProxyConsumption = () => {
    if (consumption) {
      const params = {
        transaction: props.transaction,
        consumption: consumption,
        leadsource: props.leadsource
      }
      getRequest('/dct/consumption', params, (response: any) => {
        props.getResults(1)
      }, () => {

      })
    }
  }

  const onActiveGroupClick = (value: number) => {
    setActiveGroup(value)
    setConsumptionField(value)
  }

  const goToResults = () => consumption ? setProxyConsumption() : setConsumptionError(true)

  const RadioBoxGroup = (props: { img: string, text: string, value: number, large?: boolean }) =>
    <RadioBox large={props.large} onClick={() => onActiveGroupClick(props.value)} active={props.value === activeGroup}>
      <div><img alt='' src={props.img} /></div>
      <p>{props.text}</p>
    </RadioBox>

  return (
    <Wrapper>
      <Title>VERTEL ONS JOUW ENERGIEBEHOEFTE</Title>
      <Text>Selecteer jouw huishoudgrootte</Text>
      <RadioWrapper>
        <RadioBoxGroup img={person1Img} value={1800} text='1 persoon' />
        <RadioBoxGroup img={person2Img} value={2800} text='2 personen' />
        <RadioBoxGroup img={person3Img} value={3300} text='3 personen' />
        <RadioBoxGroup img={person4Img} value={3800} text='4 personen' large={true} />
        <RadioBoxGroup img={person5Img} value={4100} text='5 of meer personen' large={true} />
      </RadioWrapper>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <Text>Verwacht energieverbruik</Text>
        <img alt='' src={infoIcon}
             style={{ cursor: 'pointer', width: '16px', marginLeft: '10px' }}
             data-tip='Het verwachte energieverbruik gebaseerd op het gemiddelde verbruik van de gekozen huishoudgrootte. <br/> Gebruik het min-en plusteken om dit zelf aan te passen (indien gewenst).' />
        <Tooltip
          multiline={true}
          place='right'
          type='dark'
          effect='solid' />
      </div>
      <ConsumptionControl error={consumtionError}>
        <img alt='' src={minusIcon} onClick={() => setConsumptionField(consumption ? consumption - 100 : 1600)} />
        <p>{consumption ? consumption : '0'} kWh per jaar</p>
        <img alt='' src={plusIcon} onClick={() => setConsumptionField(consumption ? consumption + 100 : 1600)} />
      </ConsumptionControl>

      <Controls step={3} next={() => goToResults()} back={() => props.setStep(2)} />
      {
        consumtionError && <Error>Deze gegevens hebben we nodig om je aanvraag voor zonnepanelen goed te verwerken.</Error>
      }
    </Wrapper>
  )
}

export
{
  Consumption
}
