const dataLayerPush = (label: string, action: string) => {
  window.dataLayer.push({
    event: 'dakchecktool',
    dct_category: `DCTR ${getHost()}`,
    dct_label: label,
    dct_action: action,
    dct_value: '1'
  })
}

const getHost = () => window.location.host.replace('www.', '')

export { dataLayerPush, getHost }
