import styled from 'styled-components'
import { useContext, useState } from 'react'
import { Input } from '../Input'
import { Error, StyledDropdown } from '../Shared'
import ReactTooltip from 'react-tooltip'
import { OptionsContext } from '../../../../utils/OptionsContext'

const Wrapper = styled.div`

  ${StyledDropdown} {
    width: 100% !important;
    margin: 10px 0;
  }

  h2 {
    font-family: 'EnzoOT', sans-serif !important;
    letter-spacing: normal !important;
    font-size: 22px;
    font-weight: 300;

    @media (max-width: 600px) {
      font-size: 20px;
    }
  }
`
const Title = styled.p`
  font-size: 26px;
  font-weight: 300;
  font-family: 'EnzoOT', sans-serif;

  @media (max-width: 600px) {
    font-size: 22px;
  }
`

const Text = styled.p`
  font-size: 16px;
`

const ControlButton = styled('div')<{ primary?: boolean }>`
  width: 100%;
  height: 35px;
  display: flex;
  margin-top: 20px;
  justify-content: center;
  align-items: center;
  font-weight: 300;
  font-size: 16px;
  background: ${props => props.primary && '#5fbade'};
  color: ${props => props.primary && 'white'};
  cursor: pointer;
  box-shadow: 3px 6px 5px 0 rgba(0, 0, 0, 0.25);
`

const SavingsBox = styled.div`
  border: 1px solid rgba(102, 102, 102, 0.2);
  width: 98%;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px 0;

  p:nth-child(1) {
    font-family: 'EnzoOT', sans-serif;
    font-weight: 300;
    font-size: 22px;
  }

  p {
    margin: 0;
  }

  @media (max-width: 600px) {
    width: 100%;
    p:nth-child(1) {
      font-family: 'EnzoOT', sans-serif;
      font-weight: 300;
      font-size: 20px;
    }
  }
`

const BottomWrapper = styled.div`
  width: 50%;
  margin: auto;

  label {
    margin: 0;
  }

  @media (max-width: 600px) {
    width: 80%;
  }
`

const Tooltip = styled(ReactTooltip)`
  font-size: 10px;
  font-weight: 300;
`

const ResultConfirmation = (props: { setStep(step: number): void, submitOffer: (phone: string, contract: string) => void, savings: number }) => {

  const { contractDisabled } = useContext(OptionsContext)
  const [phone, setPhone] = useState('')
  const [phoneError, setPhoneError] = useState(false)
  const [errorActive, setErrorActive] = useState(false)
  const [contract, setContract] = useState(contractDisabled ? '2' : '')
  const [contractError, setContractError] = useState(false)

  const setPhoneField = (value: string) => {
    setErrorActive(false)
    const phoneRegex = /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/
    value.length < 11 && setPhone(value)
    phoneRegex.test(value) ? setPhoneError(false) : setPhoneError(true)
  }

  const setContractField = (option: { value: string }) => {
    setContractError(false)
    setContract(option.value)
  }

  const submit = () => {
    if (!phone) setPhoneError(true)
    else if (!contract) setContractError(true)
    else {
      props.submitOffer(phone, contract)
    }
  }

  return (
    <Wrapper>
      <Title>HET RESULTAAT IS ONDERWEG!</Title>
      <Text>Je ontvangt het dakcheckresultaat binnen een paar minuten op het door jou opgegeven emailadres.</Text>
      <SavingsBox>
        <Text>Verwachte besparing op je energierekening</Text>
        <Text><b>€{Math.round(props.savings)},- per jaar</b></Text>
      </SavingsBox>
      <h2>Weten wat je precies kunt besparen met zonnepanelen?</h2>
      <Text>Vul je telefoonnummer in voor een vrijblijvend persoonlijk adviesgesprek met één van onze solar
        experts.</Text>
      <BottomWrapper>
        <Input
          fluid={true}
          error={phoneError}
          value={phone}
          numberType={true}
          correct={/^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/.test(phone)}
          tooltip='Wij gebruiken jouw telefoonnummer alleen om je nog een <br/> paar vragen te stellen, zodat we het beste advies kunnen geven.'
          onChange={setPhoneField}
          placeholder='Telefoonnummer' />
        <StyledDropdown hidden={contractDisabled} error={contractError}
                        options={[{ label: 'Huren', value: '1' }, { label: 'Kopen', value: '2' }]}
                        onChange={setContractField}
                        placeholder='Wil je huren of kopen?' />
        <Tooltip
          multiline={true}
          place='top'
          type='dark'
          effect='solid' />
        <ControlButton
          primary
          onClick={() => submit()}>
          Ik wil persoonlijk advies</ControlButton>
      </BottomWrapper>
      {
        errorActive && !phone ?
          <Error>Deze gegevens hebben we nodig om je aanvraag voor zonnepanelen goed te verwerken.</Error> :
          errorActive && phone &&
          <Error>Oeps, het gaat niet goed. Controleer de rood gemarkeerde velden.</Error>
      }
    </Wrapper>
  )
}

export { ResultConfirmation }
