import styled from 'styled-components'

const Wrapper = styled.div`
  position: absolute;
  top: 25px;
  left: 50%;
  -webkit-transform: translateX(-50%);
  background: #ffffff;
  display: flex;
  align-items: center;
  margin: auto;
  height: 80px;
  width: 400px;
  justify-content: center;
  border: 1px solid #ced4da;
  border-radius: 10px;
  color: red;
  font-size: 14px;
  z-index: 99;
  transition: opacity .5s, top .5s;
  opacity: 0;

  &.fade-enter {
    opacity: 0 ;
    top: -20px
  }

  &.fade-enter-active {
    opacity: 1;
    top: 25px;
  }

  &.fade-exit {
    opacity: 0;
    top: -20px
  }
`
const ErrorPopup = () => {
  return (
    <Wrapper>
      Oeps! Op dit moment werkt de dakchecktool helaas niet. Probeer het op een later moment nog eens.
    </Wrapper>
  )
}

export { ErrorPopup }
