import styled from 'styled-components'
import { ChangeEvent, useState } from 'react'

const Wrapper = styled('label')<{ error: boolean, correct: boolean, focus: boolean, fluid: boolean }>`
  box-sizing: border-box;
  width: ${props => props.fluid ? '100%' : '47%'};
  height: 46px;
  border: 1px solid #ced4da;
  border-radius: 4px;
  margin: 4px;
  transition: box-shadow ease-in .2s;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  
  ${props => props.error && 'box-shadow: 0 0 3px 2px #FF0000;'}
  ${props => props.focus && 'box-shadow: 0 0 3px 2px #5fbade;'}
  
  img{
    transition: opacity .5s;
    height: 20px;
    filter: invert(68%) sepia(10%) saturate(1367%) hue-rotate(154deg) brightness(103%) contrast(93%);
    opacity: 0;
    margin-left: auto;
    margin-right: 10px;
    ${props => props.correct && 'opacity: 1;'}
  }
`

const InputField = styled('input')<{ error?: boolean }>`
  box-sizing: border-box;
  outline: none;
  border: none;
  width: 80%;
  height: 100%;
  padding: 6px 12px;
  font-size: 16px;
  font-family: 'MuseoSans', sans-serif;

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`

const Info = styled.img`
  position: absolute;
  width: 16px;
  height: 16px;
  opacity: 1 !important;
  filter: none !important;
  right: -30px;
  top: 0
`

const Input = (props: {value: string, error: boolean, onChange(value: string): void, placeholder: string, correct?: boolean, fluid?: boolean, numberType?: boolean, tooltip?: string}) => {

  const {value, error, onChange, placeholder} = props

  const [focus, setFocus] = useState(false)

  const checkedIcon = require('assets/icons/checkedIcon.svg').default
  const infoIcon = require('assets/icons/info.svg').default

  return (
    <Wrapper
      fluid={!!props.fluid}
      error={error}
      correct={!!props.correct}
      focus={focus}>
      <InputField
        onFocus={()=>setFocus(true)}
        onBlur={()=>setFocus(false)}
        type={props.numberType ? 'Number' : 'Text'}
        value={value}
        placeholder={placeholder}
        onChange={(event: ChangeEvent<HTMLInputElement>) => onChange(event.target.value)} />
        <img alt='' src={checkedIcon}/>
      {
        props.tooltip &&
        <Info
          data-tip={props.tooltip}
          alt=''
          src={infoIcon} />
      }
    </Wrapper>
  )
}

export { Input }
