import styled from 'styled-components'
import { useContext, useState } from 'react'
import { Controls } from '../Controls'
import { Input } from '../Input'
import { Error, StyledDropdown } from '../Shared'
import ReactTooltip from 'react-tooltip'
import { OptionsContext } from '../../../../utils/OptionsContext'

const Wrapper = styled.div`

`

const Title = styled.p`
  font-family: 'EnzoOT', sans-serif;
  font-size: 26px;
  font-weight: 300;

  @media (max-width: 600px) {
    font-size: 22px;
  }
`

const Text = styled.p`
  font-weight: 300;
  font-size: 16px;
`

const InputsWrapper = styled('div')<{ hidden?: boolean }>`
  display: ${props => props.hidden ? 'none' : 'flex'};
  justify-content: space-between;

  @media (max-width: 600px) {
    flex-direction: column;
    width: 90%;
    margin: auto;
    label, ${StyledDropdown} {
      width: 100%;
    }
  }
`

const Tooltip = styled(ReactTooltip)`
  font-size: 10px;
  font-weight: 300;
`

const PersonalInfo = (props: { setStep(step: number): void, submitOffer(firstName: string, lastName: string, email: string, phone: string, contract: string): void }) => {

  const { contractDisabled } = useContext(OptionsContext)

  const [firstName, setFirstName] = useState('')
  const [firstNameError, setFirstNameError] = useState(false)
  const [lastName, setLastName] = useState('')
  const [lastNameError, setLastNameError] = useState(false)
  const [email, setEmail] = useState('')
  const [emailError, setEmailError] = useState(false)
  const [phone, setPhone] = useState('')
  const [phoneError, setPhoneError] = useState(false)
  const [contract, setContract] = useState(contractDisabled ? '2' : '')
  const [contractError, setContractError] = useState(false)
  const [fieldsEmptyError, setFieldsEmptyError] = useState(false)
  const [fieldsInvalidError, setFieldsInvalidError] = useState(false)

  const setFirstNameField = (value: string) => {
    setFirstName(value)
    !value ? setFirstNameError(true) : setFirstNameError(false)
    fieldsInvalidError && setFieldsInvalidError(false)
    fieldsEmptyError && setFieldsEmptyError(false)
  }

  const setLastNameField = (value: string) => {
    value.length < 32 && setLastName(value)
    !value ? setLastNameError(true) : setLastNameError(false)
    fieldsInvalidError && setFieldsInvalidError(false)
    fieldsEmptyError && setFieldsEmptyError(false)
  }

  const setEmailField = (value: string) => {
    const emailRegex = /^[\w-.]+[0-9a-z]+@([\w-]+\.)+[\w-]{2,4}$/
    setEmail(value)
    emailRegex.test(value) ? setEmailError(false) : setEmailError(true)
    fieldsInvalidError && setFieldsInvalidError(false)
    fieldsEmptyError && setFieldsEmptyError(false)
  }

  const setPhoneField = (value: string) => {
    const phoneRegex = /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/
    value.length < 11 && setPhone(value)
    phoneRegex.test(value) ? setPhoneError(false) : setPhoneError(true)
    fieldsInvalidError && setFieldsInvalidError(false)
    fieldsEmptyError && setFieldsEmptyError(false)
  }

  const setContractField = (option: { value: string }) => {
    setContractError(false)
    setContract(option.value)
  }

  const validateFields = () => {
    let hasErrors = false
    if (!firstName) {
      setFirstNameError(true)
      hasErrors = true
    }
    if (!lastName) {
      setLastNameError(true)
      hasErrors = true
    }
    if (!email) {
      setEmailError(true)
      hasErrors = true
    }
    if (!phone) {
      setPhoneError(true)
      hasErrors = true
    }
    if (!contract) {
      setContractError(true)
      hasErrors = true
    }

    !firstName || !lastName || !email || !phone ? setFieldsEmptyError(true) : setFieldsEmptyError(false)
    emailError || phoneError ? setFieldsInvalidError(true) : setFieldsInvalidError(false)
    return hasErrors
  }

  const submitFields = () => !validateFields() && !firstNameError && !lastNameError && !emailError && !phoneError && props.submitOffer(firstName, lastName, email, phone, contract)

  return (
    <Wrapper>
      <Title>ONTVANG JOUW ADVIES OP MAAT</Title>
      <Text>Vul alleen nog onderstaande gegevens in en ontvang persoonlijk advies van onze experts. Natuurlijk is dit
        geheel vrijblijvend en zit je nergens aan vast.</Text>
      <InputsWrapper>
        <Input
          error={firstNameError}
          value={firstName}
          correct={firstName !== ''}
          onChange={setFirstNameField}
          placeholder='Voornaam' />
        <Input
          error={lastNameError}
          value={lastName}
          correct={lastName !== ''}
          onChange={setLastNameField}
          placeholder='Achternaam' />
      </InputsWrapper>
      <InputsWrapper>
        <Input
          error={emailError}
          value={email}
          onChange={setEmailField}
          correct={/^[\w-.]+[0-9a-z]+@([\w-]+\.)+[\w-]{2,4}$/.test(email)}
          tooltip='Vul hier het e-mailadres in waarop je jouw advies wilt ontvangen.'
          placeholder='E-mail' />
        <Input
          error={phoneError}
          value={phone}
          numberType={true}
          onChange={setPhoneField}
          tooltip='Wij gebruiken jouw telefoonnummer allen om om je nog een paar vragen te stellen, <br/> zodat we het beste advies kunnen geven.'
          correct={/^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/.test(phone)}
          placeholder='Telefoonnummer' />
      </InputsWrapper>
      <InputsWrapper hidden={contractDisabled}>
        <StyledDropdown error={contractError} options={[{ label: 'Huren', value: '1' }, { label: 'Kopen', value: '2' }]}
                        onChange={setContractField}
                        placeholder='Wil je huren of kopen?' />
      </InputsWrapper>
      <Controls step={10} next={() => submitFields()} back={() => props.setStep(2)} />
      {
        fieldsEmptyError ?
          <Error>Deze gegevens hebben we nodig om je aanvraag voor zonnepanelen goed te verwerken.</Error> :
          fieldsInvalidError &&
          <Error>Oeps, het gaat niet goed. Controleer de rood gemarkeerde velden.</Error>
      }
      <Tooltip
        multiline={true}
        place='top'
        type='dark'
        effect='solid' />
    </Wrapper>
  )
}

export { PersonalInfo }
