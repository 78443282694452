import styled from 'styled-components'
import Dropdown from 'react-dropdown'
import 'react-dropdown/style.css';

const Error = styled.p`
  font-size: 14px;
  color: #EA1313;
  font-weight: 300;
`

const StyledDropdown = styled(Dropdown)<{ error?: boolean, hidden?: boolean}>`
  box-sizing: border-box;
  text-align: left;
  width: 47%;
  height: 46px;
  border: 1px solid #ced4da;
  border-radius: 4px;
  margin: 4px;
  transition: box-shadow ease-in .2s;
  background-color: white;
  display: ${props => props.hidden ? 'none' : 'flex'};;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  font-size: 16px;
  font-family: 'MuseoSans', sans-serif;
  ${props => props.error && 'box-shadow: 0 0 3px 2px #FF0000;'}
  
  .Dropdown-control{
    width: 100%;
    border: none;
    appearance: none;
    box-shadow: none !important;
  }
  
  .Dropdown-placeholder{
    opacity: .7;
  }
  
  .is-selected{
    opacity: 1;
  }
  
  .Dropdown-arrow{
    border-width: 8px 8px 0;
  }
`

export { Error, StyledDropdown }
