import styled from 'styled-components'

const Wrapper = styled.div`
  position: relative;
  width: 85%;
  margin: auto;
  height: 21px;
  margin-bottom: 20px;
  background: white;
  display: flex;
  justify-content: space-around;
  align-items: center;
  border-radius: 10px;
  overflow: hidden;

  div {
    position: absolute;
    left: 0;
    background-color: #5FBADE;
    transition: width 1s;
    width: 50%;
    height: 100%;
  }

  p {
    z-index: 1;
    margin: 0;
    @media (max-width: 600px) {
      font-size: 14px;
    }
  }
`

const ProgressBar = (props: { step: number }) => {

  const { step } = props

  const percentage =
    step === 1 ? 0 :
      step === 2 ? 33 :
        step === 3 ? 40 :
          step === 4 ? 60 :
            step === 5 ? 80 :
              step === 9 ? 50 :
                step === 10 ? 66 :
                  step === 11 && 100

  return (
    <Wrapper>
      <div style={{ width: `${percentage}%` }} />
      <p>{percentage}%</p>
    </Wrapper>
  )
}

export { ProgressBar }
