import { getRequest } from './Api'

interface GeocoderInterface {
  addresses: [
    {
      zip: string,
      street: string,
      number: number,
      addition: null,
      city: string,
      province: string,
      geo: {
        center: {
          rd: {
            type: string,
            coordinates: number[],
            crs: {
              type: string,
              properties: {
                name: string
              }
            }
          },
          wgs84: {
            type: number,
            coordinates: number[],
            crs: {
              type: string,
              properties: {
                name: string
              }
            }
          }
        }
      }
    }
  ]
}

const getCoordinates = (addressNumber: string, postode: string, onSuccess: (lat: number, lng: number, address: string) => void, onFailure: () => void) => {
  getRequest('/addresses', {
    number: addressNumber,
    zip: postode
  }, (response: GeocoderInterface) => {
    const foundAddress = response.addresses[0]
    const lng = foundAddress.geo.center.wgs84.coordinates[0]
    const lat = foundAddress.geo.center.wgs84.coordinates[1]
    const address = `${foundAddress.street}, ${foundAddress.number}, ${foundAddress.city}`
    onSuccess(lat, lng, address)
  }, () => onFailure())
}

export { getCoordinates }
