import React from "react";

export const OptionsContext = React.createContext({ contractDisabled: false, leadsource: 179});

const OptionsContextProvider = (props: { children: React.ReactNode, contractDisabled: boolean, leadsource: number }) => {
  const options = {
    contractDisabled: props.contractDisabled,
    leadsource: props.leadsource || 179
  }
  return (
    <OptionsContext.Provider value={options}>
      {props.children}
    </OptionsContext.Provider>
  )
}

export default OptionsContextProvider;
