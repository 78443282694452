import styled, { css } from 'styled-components'

const Wrapper = styled.div`
  width: 90%;
  margin: auto;
  margin-top: 30px;
`

const ControlButton = styled('div')<{ primary?: boolean, clickable?: boolean}>`
  width: 265px;
  margin: auto;
  font-family: "Museo Sans", sans-serif;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 300;
  font-size: 16px;
  color: rgba(46, 42, 40, 0.6);
  cursor: ${props => props.clickable ? 'pointer' : 'initial'};
  ${props => props.primary && css`
    font-size: 16px;
    background: #5fbade;
    color: white;
  `}
  @media(max-width: 600px){
    margin-bottom: 20px;
  }
`

const Controls = (props: { step: number, next(): void, back(): void, jumpToForm?(): void, disableDakCheck?: boolean}) => {

  const { step, next, back} = props

  return (
    <Wrapper>
      {
        step === 2 ?
          <div style={{display: 'flex', justifyContent: 'space-between'}}>
            {
              !props.disableDakCheck &&
              <ControlButton
                onClick={() =>  next()}
                primary clickable>Zelf even kijken</ControlButton>
            }
            <ControlButton
              onClick={() => props.jumpToForm && props.jumpToForm()}
              primary clickable>Aanbod op maat </ControlButton>
          </div> :
          <ControlButton
            onClick={() => next()}
            primary clickable>{step === 1 ? 'START DAKCHECK' : step === 10 ? 'AANVRAGEN' : step === 4 ? 'VERSTUUR DAKCHECK RESULTAAT' : step === 9 ? 'GEGEVENS AANVULLEN' :'DOORGAAN'}</ControlButton>
      }
      <ControlButton
        onClick={() => step !== 1 && back()}
        clickable={step !== 1}
      >{step === 1 ? '' : 'Terug'}</ControlButton>
    </Wrapper>
  )
}

export { Controls }
