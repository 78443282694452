import ReactDOM from 'react-dom'
import { App } from './App'
import * as serviceWorker from './serviceWorker'

let apiEnv = ''

// @ts-ignore
window.renderDct = (rootId: string, environment: string, contractDisabled?: boolean, leadsource: number) => {
  apiEnv = environment
  ReactDOM.render(<App contractDisabled={!!contractDisabled} leadsource={leadsource} />, document.getElementById(rootId))
}

serviceWorker.unregister()

export { apiEnv }
