import styled, { keyframes } from 'styled-components'
import Loader from 'react-loader-spinner'

const fadeIn = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  z-index: 999;
  animation: ${fadeIn} .5s;
  background-color: rgba(0, 0, 0, .2);
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`

const SpinnerOverlay = () => {

  return (
    <Wrapper>
      <Loader
        type='Rings'
        color='#5fbade'
        height={100}
        width={100}
        timeout={0}
      />
    </Wrapper>
  )
}

export { SpinnerOverlay }
