import GoogleMapReact from 'google-map-react'
import styled from 'styled-components'

const Wrapper = styled.div`
  width: 300px;
  height: 300px;
`


const GoogleMap = (props: { lat: number, lng: number, streetView?: boolean, hideStreetView?: () => void }) => {

  const apiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY ? process.env.REACT_APP_GOOGLE_MAPS_API_KEY : ''

  const markerImage = require('assets/icons/pinIcon.png').default
  const markerPosition = { lat: props.lat, lng: props.lng }

  const mapOptions = {
    mapTypeId: 'satellite',
    fullscreenControl: false,
    zoomControl: true,
    draggable: false,
    streetViewControl: false
  }

  const setStreetView = (map: any, maps: any) => {
    if (map) {
      const position = new maps.LatLng(markerPosition)
      const panorama = map.getStreetView((panoData : any, status : any)=>{
        console.log(status)
      })

      const panoOptions = {
        position: position,
        visible: true,
        motionTrackingControlOptions: false,
        disableDefaultUI: true,
        clickToGo: false,
        enableCloseButton: false
      }
      panorama.setOptions(panoOptions)
      map.setStreetView(panorama)
      maps.event.addListener(panorama, 'position_changed', function() {
        const heading = maps.geometry.spherical.computeHeading(panorama.getPosition(), position)
        panorama.setPov({
          heading: heading,
          pitch: 0
        })
      })
      maps.event.addListener(panorama, 'status_changed', function() {
        const status = panorama.getStatus()
        if(status === 'ZERO_RESULTS'){
          props.hideStreetView && props.hideStreetView()
        }
      })
    }
  }

  const renderMarker = (map: any, maps: any) => {
    return  new maps.Marker({
      position: markerPosition,
      map,
      icon: markerImage
    })
  }


  return (
    <Wrapper id={'panorama'}>
      <GoogleMapReact
        bootstrapURLKeys={{ key: apiKey, libraries: 'geometry' }}
        defaultCenter={markerPosition}
        defaultZoom={19}
        onGoogleApiLoaded={({ map, maps }) => props.streetView ? setStreetView(map, maps) : renderMarker(map, maps)}
        yesIWantToUseGoogleMapApiInternals={true}
        options={mapOptions} />
    </Wrapper>
  )
}

export { GoogleMap }
