import styled from 'styled-components'

const Wrapper = styled.div``

const Title = styled.p`
  font-size: 26px;
  font-weight: 300;
  font-family: 'EnzoOT', sans-serif;
  
  @media(max-width: 600px){
    font-size: 22px;
  }
`
const Text = styled.p`

`

const OfferConfirmation = () => {

  return (
    <Wrapper>
      <Title>JE AANVRAAG IS ONTVANGEN!</Title>
      <Text>Een solar expert neemt binnenkort telefonisch contact met je op voor een persoonlijk advies.</Text>
    </Wrapper>
  )
}

export { OfferConfirmation }
