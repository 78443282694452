import styled from 'styled-components'
import ReactTooltip from 'react-tooltip'
import { ChangeEvent, useEffect, useState } from 'react'
import { Controls } from '../Controls'
import { Input } from '../Input'
import { Error } from '../Shared'
import { dataLayerPush } from '../../../../utils/helperFunctions'

const Wrapper = styled.div`

`

const Text = styled.p`
  font-weight: 300;
  font-size: 16px;
  margin: 16px 0;

  @media (max-width: 600px) {
    font-size: 14px;
  }
`

const ContentBox = styled.div`
  width: 420px;
  margin: auto;
  margin-bottom: 30px;

  div {
    display: flex;
    margin-bottom: 15px;

    img {
      margin-right: 25px;
      margin-left: 5px;
    }

    p {
      margin: 0;
    }

    p:nth-child(3) {
      display: none;
    }
  }

  div:first-of-type {
    img {
      margin-right: 19px;
      margin-left: 0;
    }
  }

  @media (max-width: 500px) {
    width: 250px;
    div {
      p:nth-child(3) {
        display: initial;
      }

      p:nth-child(2) {
        display: none;
      }
    }
  }
`

const InputWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;

  @media (max-width: 600px) {
    flex-direction: column;
    label {
      width: 90%;
      margin: auto;
      margin-bottom: 14px;
    }
  }
`

const CheckboxGroup = styled('label')<{ error: boolean }>`
  display: flex;
  align-items: center;
  cursor: pointer;

  & p {
    margin: 14px 0;
    margin-left: 10px;
    font-size: 14px;
    font-weight: 300;
  }

  a {
    text-decoration: none;
    color: #5FBADE;
  }

  img {
    width: 20px;
    height: 20px;
    margin-left: 10px;
  }

  @media (max-width: 600px) {
    width: 90%;
    margin: auto;
    p, a {
      text-align: left;
    }

    input {
      transform: scale(1.5);
      margin-left: 10px;
    }
  }

  input {
    ${props => props.error && 'box-shadow: 0 0 3px 2px #FF0000;'}
  }
`

const Tooltip = styled(ReactTooltip)`
  font-size: 10px;
  font-weight: 300;
`

const Address = (props: { next(address: string, postCode: string): void, initialAddress: { address: string, postCode: string } }) => {

  const infoIcon = require('assets/icons/info.svg').default
  const moneyIcon = require('assets/icons/money.svg').default
  const comunityIcon = require('assets/icons/comunity.svg').default
  const clockIcon = require('assets/icons/clock.svg').default

  const [address, setAddress] = useState(props.initialAddress.address)
  const [addressError, setAddressError] = useState(false)
  const [postCode, setPostCode] = useState(props.initialAddress.postCode)
  const [postCodeError, setPostCodeError] = useState(false)
  const [checkbox, setCheckbox] = useState(false)
  const [checkboxError, setCheckboxError] = useState(false)
  const [fieldsEmptyError, setFieldsEmptyError] = useState(false)
  const [fieldsInvalidError, setFieldsInvalidError] = useState(false)
  const [interactionStarted, setInteractionStarted] = useState(false)

  const setAddressField = (value: string) => {
    if (value) {
      !interactionStarted && setInteractionStarted(true)
      value.length < 8 && value.match(/^\d+[a-zA-Z]*$/) && setAddress(value.toLocaleUpperCase())
      addressError && setAddressError(false)
    } else {
      setAddress(value)
      setAddressError(true)
    }
  }

  const setPostCodeField = (value: string) => {
    if (value) {
      !interactionStarted && setInteractionStarted(true)
      postCodeError && setPostCodeError(false)
      value.length < 5 ?
        value[value.length - 1].match(/^\d+$/) !== null && setPostCode(value) :
        value.length < 7 && value[value.length - 1].match(/[a-z]/i) && setPostCode(value.toLocaleUpperCase())
    } else {
      setPostCode(value)
      setPostCodeError(true)
    }
  }

  useEffect(() => {
    checkbox && setCheckboxError(false)
  }, [checkbox])

  useEffect(() => {
    interactionStarted && dataLayerPush('General', 'Step 1 interaction')
  }, [interactionStarted])

  const validateFields = () => {
    let hasErrors = false
    if (!address) {
      setAddressError(true)
      setFieldsEmptyError(true)
      hasErrors = true
    } else if (!hasErrors) {
      setFieldsEmptyError(false)
    }
    if (postCode.length !== 6) {
      setPostCodeError(true)
      !postCode ? setFieldsEmptyError(true) : setFieldsInvalidError(true)
      hasErrors = true
    } else if (!hasErrors) {
      setFieldsEmptyError(false)
      setFieldsInvalidError(false)
    }
    if (!checkbox) {
      setCheckboxError(true)
      setFieldsEmptyError(true)
      hasErrors = true
    } else if (!hasErrors) {
      setFieldsEmptyError(false)
    }
    return hasErrors
  }

  const submitAddress = () => !validateFields() && props.next(address, postCode)

  return (
    <Wrapper>
      <ContentBox>
        <div>
          <img alt='' src={moneyIcon} />
          <Text>Bespaar tot €825 per jaar op je energierekening</Text>
          <Text>Bespaar tot €825 per jaar </Text>
        </div>
        <div>
          <img alt='' src={comunityIcon} />
          <Text>Al ruim 75.000 daken gecheckt</Text>
          <Text>Al ruim 75000 daken gecheckt</Text>
        </div>
        <div>
          <img alt='' src={clockIcon} />
          <Text>Binnen 2 minuten resultaat</Text>
          <Text>Binnen 2 minuten resultaat</Text>
        </div>
      </ContentBox>
      <Text>Start de dakcheck en ontdek hoeveel jij kunt besparen met zonnepanelen</Text>
      <InputWrapper>
        <Input
          onChange={setPostCodeField}
          correct={postCode.length === 6}
          value={postCode}
          error={postCodeError}
          placeholder='Postcode' />
        <Input
          onChange={setAddressField}
          correct={address !== ''}
          value={address}
          error={addressError}
          placeholder='Huisnummer' />
      </InputWrapper>
      <CheckboxGroup error={checkboxError}>
        <input
          onChange={(event: ChangeEvent<HTMLInputElement>) => setCheckbox(event.target.checked)}
          type='checkbox' />
        <p>Ik heb een koophuis (zonder VVE) en ga akkoord met de
          <a target='_blank' rel='noreferrer' href='https://www.solease.nl/privacy'> privacyverklaring</a>.
        </p>
        <img
          data-tip='Op dit moment kun je alleen een dakcheck doen als <br/> je beschikt over een koopwoning (zonder VVE).'
          alt=''
          src={infoIcon} />
        <Tooltip
          multiline={true}
          place='right'
          type='dark'
          effect='solid' />
      </CheckboxGroup>
      <Controls step={1} next={submitAddress} back={() => null} />
      {
        fieldsEmptyError ?
          <Error>Deze gegevens hebben we nodig om je aanvraag voor zonnepanelen goed te verwerken.</Error> :
          fieldsInvalidError &&
          <Error>Oeps, het gaat niet goed. Controleer de rood gemarkeerde velden.</Error>
      }
    </Wrapper>
  )
}

export { Address }
